import { Button, ButtonOwnProps } from '@mui/material';
import { ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRight } from 'assets/volvoIcons';
import { Id } from 'domain/Id';
import { ServiceProtocolStatus } from 'domain/serviceProtocol';
import {
	getLinkOpenInitiatedServiceProtocol,
	getLinkOpenServiceProtocolView,
} from 'library/components/link';

interface ServiceProtocolDetailsLinkProps
	extends Id,
		Pick<ButtonOwnProps, 'color' | 'variant'> {
	Icon?: ReactNode;
	status: ServiceProtocolStatus;
	drivelineId: number;
}

export const ServiceProtocolDetailsLink = memo(
	({
		color,
		drivelineId,
		Icon = <ArrowRight />,
		id,
		status,
		variant = 'text',
	}: ServiceProtocolDetailsLinkProps) => {
		const { t } = useTranslation(['commonButton']);
		const isInitiated = status === 'Initiated';

		const serviceProtocolPath = isInitiated
			? getLinkOpenInitiatedServiceProtocol(id, drivelineId)
			: getLinkOpenServiceProtocolView(id, drivelineId);

		return (
			<Button
				// TODO: use component={Link} from react router when #705677 support for MVC via variable VITE_IS_SERVICE_PROTOCOL_VIEW
				color={color}
				endIcon={Icon}
				href={serviceProtocolPath}
				sx={{ mr: 2 }}
				variant={variant}>
				{isInitiated ? t('commonButton:edit') : t('commonButton:view')}
			</Button>
		);
	}
);

ServiceProtocolDetailsLink.displayName = 'ServiceProtocolDetailsLink';
