import { Box, Button, Typography } from '@mui/material';
import { HTMLAttributeAnchorTarget } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseDialog } from 'library/components/controls';
import {
	getLinkCreateNewServiceProtocol,
	getLinkOpenInitiatedServiceProtocol,
} from 'library/components/link';
import { useBlockClick } from 'library/hooks';

export type ServiceProtocolIdProp = {
	serviceProtocolId: number;
};

export const GenerateProtocolWarningDialog = ({
	serviceProtocolId,
	serviceScheduledDate,
	chassisId,
	drivelineId,
	onClose,
	linkTarget = '_self',
}: ServiceProtocolIdProp & {
	chassisId: string;
	drivelineId: number;
	onClose: () => void;
	serviceScheduledDate?: Date | null;
	linkTarget?: HTMLAttributeAnchorTarget | undefined;
}): JSX.Element => {
	const { t } = useTranslation(['common', 'serviceProtocol']);
	const { disabled, handleClick } = useBlockClick();

	// disable button if the old UI page will open in the same tab
	const shouldDisableButton = linkTarget == '_self' && disabled;

	return (
		<BaseDialog
			title={t('common:warning') + ` - ${chassisId}`}
			onClose={onClose}
			sx={{
				'& .base-dialog__title': {
					bgcolor: 'background.default',
				},
			}}>
			<Typography py={2}>
				{t('serviceProtocol:generate-popup-message')}
			</Typography>

			<Box display="flex" gap={1}>
				<Button
					// TODO: use component={Link} from react router when #705677 support for MVC via variable VITE_IS_SERVICE_PROTOCOL_VIEW
					variant="outlined"
					color="secondary"
					target={linkTarget}
					disabled={shouldDisableButton}
					href={getLinkOpenInitiatedServiceProtocol(
						serviceProtocolId,
						drivelineId
					)}
					onClick={handleClick}>
					{t('serviceProtocol:generate-popup-open-protocol-button')}
				</Button>

				<Button
					// TODO: use component={Link} from react router when #705677 support for MVC via variable VITE_IS_SERVICE_PROTOCOL_VIEW
					disabled={shouldDisableButton}
					variant="outlined"
					target={linkTarget}
					href={getLinkCreateNewServiceProtocol(
						drivelineId,
						chassisId,
						serviceScheduledDate
					)}>
					{t('serviceProtocol:generate-popup-create-protocol-button')}
				</Button>
			</Box>
		</BaseDialog>
	);
};
