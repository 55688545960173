import { Buffer } from 'buffer';
import { generatePath } from 'react-router-dom';

import StringFormatter from 'library/formatters/StringFormatter';
import {
	generateServiceProtocolUrl,
	serviceProtocolView,
} from 'main/routes/urls/RouteUrls';

// Is new feature available - #705677 - TODO: REMOVE AFTER PROD RELEASE -> support for MVC
const VITE_IS_SERVICE_PROTOCOL_VIEW = import.meta.env
	.VITE_IS_SERVICE_PROTOCOL_VIEW;
const isNewSP = VITE_IS_SERVICE_PROTOCOL_VIEW === 'true';

const vppnPrefix = 'vppn';
const CommissioningMarineCheckListPublicationNumber = 47715367;
const volvoShopPartsUrl = 'https://www.volvopenta.com/shop/999222';
export const volvoWOPUrl =
	'https://securemobile.volvo.com/InfoTree/infotree-ui/#/browse/index?portal=browse&function=4Wj7fUkw';

export const getCorrectPcUrl = (url: string | null | undefined) => {
	if (window.location.hostname.includes(vppnPrefix) && url != null) {
		const newUrl = new URL(url);
		newUrl.hostname = window.location.hostname;

		return newUrl.toString();
	}

	return url;
};

const getPcReturnUrlQuery = (returnUrl = window.location.href) =>
	`returnUrl=${Buffer.from(returnUrl).toString('base64')}`;

const pcHostUrl = getCorrectPcUrl(import.meta.env.VITE_PC_HOST);

export const publicationsUrl =
	getCorrectPcUrl(import.meta.env.VITE_URL_PUBLICATIONS) ?? '';
export const plannerUrl =
	getCorrectPcUrl(import.meta.env.VITE_URL_MAINTENANCE_PLANNER) ?? '';
export const calculatorUrl =
	getCorrectPcUrl(import.meta.env.VITE_URL_SERVICE_CALCULATOR) ?? '';
export const calculationsUrl =
	getCorrectPcUrl(import.meta.env.VITE_URL_MY_CALCULATIONS) ?? '';
export const swkUrl = getCorrectPcUrl(import.meta.env.VITE_URL_SWK) ?? '';
export const repairUrl = getCorrectPcUrl(import.meta.env.VITE_URL_REPAIR) ?? '';

export const publicationCommissioningMarineCheckListUrl = `${publicationsUrl}?st=publicationNumber&sp=${CommissioningMarineCheckListPublicationNumber}`;

export const getPublicationProductIndividualUrl = (serialNumber: string) =>
	`${publicationsUrl}?st=serialnumber&sp=${serialNumber}`;

export const getPublicationOfflineServiceProtocolUrl = (
	serialNumber: string,
	language: string | null
) =>
	`${publicationsUrl}?st=serialnumber&sp=${serialNumber}&r[0].f=PublicationType&r[0].v=Service%20Protocol&r[1].f=Language&r[1].v=${language ?? 'en-US'}`;

export const getPublicationOnlineServiceManualUrl = (
	serialNumber: string,
	language: string | null
) =>
	`${publicationsUrl}?st=serialnumber&sp=${serialNumber}&r[0].f=PublicationType&r[0].v=Service%20and%20Maintenance%20Manual&r[1].f=Language&r[1].v=${language ?? 'en-US'}`;

export const getVolvoShopPartsBySNUrl = (serialNumber: string) =>
	`${volvoShopPartsUrl}/workshop/parts-list?ft=${serialNumber}`;
export class PcLinks {
	public static getProductIndividualLink(serialNumber: string): string {
		return StringFormatter.Format(
			'{0}/ProductIndividual/View?SerialNumber={1}',
			pcHostUrl ?? '',
			encodeURIComponent(serialNumber)
		);
	}
}

export const getUnitOverviewLink = (unitId: number) =>
	`${pcHostUrl}/UnitOverview/${unitId}`;

export const getRegisterDemoUseLink = (unitId: number) => {
	const buff = Buffer.from(getUnitOverviewLink(unitId));
	const unitOverviewEncoded = buff.toString('base64');

	return `${pcHostUrl}/Register/DemoUse/${unitId}?returnUrl=${unitOverviewEncoded}`;
};

export const getUnitActionsLink = (
	mvcAction: string,
	id: number,
	returnUrl?: string
) => `${pcHostUrl}/${mvcAction}/${id}?${getPcReturnUrlQuery(returnUrl)}`;

export const getRelocateToLink = (id: string | number) =>
	`${pcHostUrl}/UnitOverview/TransferUnit/${encodeURIComponent(id)}`;

export const getUnitPrintPreviewLink = (id: string | number) =>
	`${pcHostUrl}/UnitOverview/PrintPdfWithCoverages/${encodeURIComponent(id)}`;

// workaround to redirect to old MVC, for place, where drivelineId is hard to get
export const getOldPCServiceProtocolDetailsLink = (id: number) =>
	getUnitActionsLink(
		'ServiceProtocol/GetServiceProtocol',
		id,
		getPcReturnUrlQuery()
	);

export const getLinkOpenServiceProtocolView = (
	id: number,
	drivelineId: number
) =>
	isNewSP
		? generatePath(
				`${import.meta.env.BASE_URL}/${serviceProtocolView}?drivelineId=${drivelineId}`,
				{
					serviceProtocolId: id.toString(),
				}
			)
		: getUnitActionsLink(
				'ServiceProtocol/GetServiceProtocol',
				id,
				getPcReturnUrlQuery()
			);

export const getServiceProtocolPrintLink = (id: string | number) =>
	`${pcHostUrl}/ServiceProtocol/Print?protocolId=${encodeURIComponent(id)}`;

export const getLinkOpenInitiatedServiceProtocol = (
	serviceProtocolId: number,
	drivelineId: number
) =>
	isNewSP
		? generatePath(
				`${import.meta.env.BASE_URL}/${generateServiceProtocolUrl}?drivelineId=${drivelineId}`,
				{
					serviceProtocolId: serviceProtocolId.toString(),
				}
			)
		: getUnitActionsLink(
				'ServiceProtocol/GetServiceProtocol',
				serviceProtocolId,
				getPcReturnUrlQuery()
			);

export const getLinkCreateNewServiceProtocol = (
	drivelineId: number,
	chassisId: string | number,
	serviceScheduledDate?: Date | null
) =>
	isNewSP
		? generatePath(
				`${import.meta.env.BASE_URL}/${generateServiceProtocolUrl}?drivelineId=${drivelineId}&serviceDate=${serviceScheduledDate?.toISOString()}`
			)
		: `${pcHostUrl}/ServiceProtocol/GenerateView?chassisId=${encodeURIComponent(
				chassisId
			)}&${getPcReturnUrlQuery()}` +
			(serviceScheduledDate
				? `&serviceDate=${serviceScheduledDate.toISOString()}`
				: '');

export const getChangeLanguageLink = () =>
	`${pcHostUrl}/Utility/RefreshSessionAndCultureCookie`;

const getRepairActionLink = (action: string) => `${repairUrl}/${action}`;

const getRepairLink = (action: string, repairId: number) =>
	`${getRepairActionLink(action)}/${repairId}?${getPcReturnUrlQuery()}`;

export const getRepairViewLink = (repairId: number) =>
	getRepairLink('View', repairId);

export const getRepairEditLink = (repairId: number) =>
	getRepairLink('Edit', repairId);

export const getRepairAddLink = (serialNumber: string) =>
	`${getRepairActionLink('Add')}?serialNumber=${serialNumber}&${getPcReturnUrlQuery()}`;

export const getRepairPrintLink = (repairId: number, serialNumber: string) =>
	`${pcHostUrl}/ProductIndividual/PrintRepair?repairId=${repairId}&serialNumber=${serialNumber}`;
